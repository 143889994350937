import React, { useEffect, useState } from 'react'

const axios = require("axios").default
const qs = require("qs")

const ConfirmOfferRequest = ({ location }) => {
    const offersEndpoint = `${process.env.STRAPI_ENDPOINT}/offers`
    useEffect(() => {
        const queryString = decodeURIComponent(location?.search.substring(1));
        const queryParams = qs.parse(queryString)
        console.log('queryParams --- ', queryParams)
        // const myCode = searchParams.get('code');
        // const myState = searchParams.get('state')
        if (queryParams.hasOwnProperty('code') & queryParams.hasOwnProperty('state')) {
            console.log('has both code and state props')

            confirmOffer(queryParams);
        }
    }, []);

    const confirmOffer = ({ code, state }) => {
        const encodedParams = qs.stringify(
            {
                code,
                state
            },
            {
                encodeValuesOnly: true, // prettify URL
            }
        )
        console.log('test 4 --- ', encodedParams)

        axios
            .get(`${offersEndpoint}?${encodedParams}`, {
                data: {}
            }, {
                headers: {
                    Authorization: `Bearer ${process.env.API_KEY}`,
                }
            })
            .then(response => {
                console.log('response --- ', response)
                alert('Cererea a fost acceptata cu succes! Veti primi in curand un mesaj pe email ,atat dvs. cat si clientul dvs., cu detaliile noii sedinte!')

                window.location.href = "/"
            })
            .catch((err) => {
                console.log('axios offer err -- ', err.response)

                if (err.response.data.error.message.includes('Offer was already confirmed')) {
                    alert('Cererea a fost deja confirmata!')
                    window.location.href = "/"
                }
                else if (err.response.data.error.message.includes('Verification codes do not match'))
                    alert('Codurile de verificare nu coincid! Va rugam nu modificati url-ul pe care ati fost redirectionat!!')

            })
    }

    return (
        <></>
    )
}

export default ConfirmOfferRequest
