import React from "react"
import Layout from "../markup/layout/layout"
import Seo from "../markup/layout/seo"
import { PageBanner } from "../markup/layout/PageBanner"
import ConfirmOfferRequest from "../components/ConfirmOfferRequest"

function confirma_cerere_oferta({ location }) {
  const pageTitle = "Confirma Cerere Oferta"
  return (
    <Layout>
      <Seo title={pageTitle} />
      <PageBanner
        pageTitle={pageTitle}
        isHomePage={false}
        pageInfo={{
          title: pageTitle,
          parent: { title: "", slug: "" },
          hasParent: false,
        }}
      />
      <ConfirmOfferRequest location={location} />
    </Layout>
  )
}

export default confirma_cerere_oferta
